import '@fontsource/prompt/400.css';
import '@fontsource/prompt/500.css';
import '@fontsource/prompt/600.css';
import './lib/remixicon/remixicon.css';

import packageJson from './package.json';

// eslint-disable-next-line no-console
console.log(`%c version: ${packageJson.version}`, 'font-size: 16px;');

function initGTM() {
	if (document.location.origin === 'https://softkit.dev' || 'https://www.softkit.dev') {
		console.log('loading GTM');

		if (window.isGTMLoaded) {
			return false;
		}

		window.isGTMLoaded = true;

		const script = document.createElement('script');

		script.type = 'text/javascript';
		script.async = true;
		script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GOOGLE_TAG_MANAGER_KEY}`;

		script.onload = () => {
			window.dataLayer = window.dataLayer || [];
			function gtag() {
				dataLayer.push(arguments);
			}
			gtag('js', new Date());

			gtag('config', `${process.env.GATSBY_GOOGLE_TAG_MANAGER_KEY}`);
		};

		document.head.appendChild(script);
	}
}

function loadGTM(event) {
	initGTM();
	event.currentTarget.removeEventListener('scroll', loadGTM);
	event.currentTarget.removeEventListener('mousemove', loadGTM);
	event.currentTarget.removeEventListener('touchstart', loadGTM);
}

export function onClientEntry() {
	document.onreadystatechange = () => {
		if (document.readyState !== 'loading') {
			setTimeout(initGTM, 1500);
		}
	};

	document.addEventListener('scroll', loadGTM);
	document.addEventListener('mousemove', loadGTM);
	document.addEventListener('touchstart', loadGTM);
}
